<template>
  <footer>
    <aside class="blurb">
      All of us at GammaTeam Security are honored for the opportunity to partner with you and provide you with maximum standard of service in the security field<span class="end-quote">&quot;</span>
    </aside>
    <article class="careers">
      <h3><router-link to="/careers">Careers</router-link></h3>
      Join our team!
      <router-link class="button" to="/careers">Apply Today!</router-link>
    </article>
    <article class="services">
      <h3><router-link to="/services">Services</router-link></h3>
      <ul>
        <li v-for="(item, i) in services" :key="i">
          <template v-if="item.action.type === 'page'">
            <router-link :to="item.action.location">{{ item.label }}</router-link>
          </template>
        </li>
      </ul>
    </article>
    <article class="contact">
      <h3><router-link to="/contact-us">Contact Us</router-link></h3>
      <div v-for="(state) in Object.keys($store.state.contact)" :key="'contact_' + state">
        <h5>{{ $store.state.contact[state].title }}</h5>
        {{ $store.state.contact[state].address1 }},<br />
        {{ $store.state.contact[state].city }}, {{ $store.state.contact[state].state }} {{ $store.state.contact[state].zip }}<br />
        Office: {{ $store.state.contact[state].phone }}<br />
        Fax: {{ $store.state.contact[state].fax }}<br />
        <a :href="'mailto:' + $store.state.contact[state].email">{{ $store.state.contact[state].email }}</a>
      </div>
    </article>
    <article class="socials">
      <h3>Social Media</h3>
      <div>
        <a href="https://www.facebook.com/gammateamcorp/" target="_blank">
          <i class="fa fa-facebook-official" aria-hidden="true"></i>
        </a>
        <a href="https://www.linkedin.com/company/gammateam-security-inc/" target="_blank">
          <i class="fa fa-linkedin-square" aria-hidden="true"></i>
        </a>
        <a href="https://www.instagram.com/gammatsecurity/?hl=en" target="_blank">
          <i class="fa fa-instagram" aria-hidden="true"></i>
        </a>
      </div>
    </article>
    <article class="copy">
      Copyright &copy; {{ copyrightYearComputed }} GammaTeamCorp.com<br />
      All images, content and information are property of Gamma Team Security, Inc.<br /><br />
      Hours are Monday-Friday (8am-5pm)<br />
      Ph: {{ $store.state.contact.IL.phone }} | Fax: {{ $store.state.contact.IL.fax }}<br />
      <a :href="'mailto:' + $store.state.contact.IL.email">{{ $store.state.contact.IL.email }}</a>
    </article>
    <article class="login">
      <router-link to="login">[ Login ]</router-link>
    </article>
  </footer>
</template>

<script>
import store from '@/store'

export default {
  name: 'gammaFooter',
  data: () => ({
    services: []
  }),
  computed: {
    copyrightYearComputed: () => {
      return new Date().getFullYear()
    }
  },
  created () {
    const $ = require('jquery')
    store.state.services.forEach((item) => {
      $('#wrapper').animate({ scrollTop: 0 }, 'slow')
      this.services.push({ label: item, action: { type: 'page', location: '/services/' + this.getUrlFromName(item) } })
    })
  }
}
</script>

<style scoped>
/* footer */
footer {
  display: block;
  width: 100%;
  background-color: black;
  color: rgba(255,255,255,.75);
  height: 100vh;
  margin-top: 100px;
  padding-top: 40px;
}
footer a {
  color: #fff;
  text-decoration: none;
}
footer aside.blurb {
  text-align: left;
  width: 25%;
  margin-left: 5%;
  float: left;
  line-height: 1.5em;
  position: relative;
  padding-left: 40px;

}
footer aside.blurb::before {
  content: '\201c';
  position: absolute;
  top: 10px;
  left: 8px;
  font-size: 5em;
  color: #2a7afa;
}
footer article {
  width: 20%;
  margin-left: 2%;
  float: left;
  text-align: left;
}
footer article.careers {
  display: none;
}
footer article h3 {
  color: #2a7afa;
  font-size: 1.8em;
  margin-bottom: 20px;
}
footer article h3 a {
  color: inherit;
}
footer article h3::after {
  content: '';
  display: block;
  border-top: solid 1px #666;
  margin: 5px 0;
}
footer article.services ul li {
  display: block;
  list-style: none;
  line-height: 2em;
}
footer article.contact div {
  letter-spacing: 1.5px;
}
footer article.contact div:last-child {
  margin-top: 40px;
}
footer article.copy {
  display: block;
  width: 100%;
  padding: 0 20%;
  margin: 40px auto 0 auto;
  text-align: center;
  font-size: .8em;
  letter-spacing: 2px;
  opacity: .7;
}
footer article.copy::before {
  content: '';
  display: block;
  clear: both;
  border-top: solid 1px rgba(255,255,255,.75);
  margin-bottom: 10px;
}
footer article.socials i {
  margin: 0 5px;
  font-size: 2em;
}
footer article.login {
  display: block;
  width: 100%;
  padding: 0 20%;
  margin: 40px auto 0 auto;
  text-align: center;
  font-size: .8em;
  letter-spacing: 2px;
  opacity: .7;
}

/* media queries */
@media screen and (max-width: 1160px) {
  footer {
    height: 80vh;
  }
  footer aside.blurb {
    margin-left: 2%;
  }
  footer article h3 {
    font-size: 1.5em;
  }
  footer article.contact div {
    font-size: 1em;
    line-height: 1.2em;
    letter-spacing: 1.3px;
  }
  footer article {
    margin-left: 3%;
  }
}
/* media queries */
@media screen and (max-width: 980px) {
  footer article h3 {
    font-size: 1.3em;
  }
}
/* media queries */
@media screen and (max-width: 920px) {
  footer {
    height: 80vh;
  }
  footer aside.blurb {
    margin-left: 2%;
    font-size: .9em;
    padding-left: 30px;
  }
  footer aside.blurb::before {
    font-size: 3.5em;
  }
  footer article h3 {
    font-size: 1.2em;
  }
  footer article.contact div {
    font-size: .9em;
    line-height: 1.3em;
    letter-spacing: 1px;
  }
  footer article.contact h5 {
    letter-spacing: 1.2px;
  }
}
/* media queries */
@media screen and (max-width: 770px) {
  footer {
    height: 85vh;
    margin-top: 40px;
  }
  footer aside.blurb {
    margin-left: 1%;
    width: 25%;
  }
  footer article h3 {
    font-size: 1em;
  }
  footer article.contact h5 {
    font-size: 1em;
  }
}
/* media queries */
@media screen and (max-width: 730px) {
  footer article.contact div {
    font-size: .8em;
    line-height: 1.2em;
    letter-spacing: 1px;
  }
  footer article.services ul li {
    line-height: 1.5em;
  }
}
/* media queries */
@media screen and (max-width: 650px) {
  footer {
    height: 100%;
    padding-bottom: 20px;
  }
  footer aside.blurb {
    float: none;
    display: block;
    width: 97%;
    text-align: center;
  }
  footer article {
    float: none;
    display: block;
    width: 80%;
    text-align: center;
    margin: 40px auto 0 auto;
  }
  footer article.careers {
    display: block;
  }
  footer article.careers a.button {
    display: block;
    width: 200px;
    margin: 15px auto 0 auto;
    background-color: #2a7afa;
    border-radius: 20px;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    box-shadow: 4px 4px 10px #222;
  }
  footer article.socials i {
    font-size: 40px;
    margin: 0 10px;
  }
}
/* media queries */
@media screen and (max-width: 450px) {
  footer article.copy {
    padding: 0 10%;
  }
}
</style>
