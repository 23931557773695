<template>
  <div>
  <!-- Wrapper -->
    <header>
      <div>
        <h1>GammaTeam Security: Admin Portal</h1>
        <span class="user_details">Welcome, {{ $store.state.auth.user.email }}</span>
        <v-btn
            @click.stop="logout"
            depressed
            class="logout"
        >
          LOGOUT
        </v-btn>
      </div>
    </header>

    <overlay></overlay>

    <router-view></router-view>
  </div>
</template>

<script>
import overlay from '@/components/overlay'
export default {
  name: 'AdminLayout',
  components: { overlay },
  data: () => ({
  }),
  methods: {
    logout: function () {
      console.log('Logging out!')
      window.axios.post('/logout')
        .then((response) => {
          if (response.data.success) {
            console.log('Success!')
            this.$store.commit('logoutUser')
          } else {
            console.error('Failed to logout!')
            console.error(response)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }
}
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background: rgba(100,100,100,1);
  background: linear-gradient(90deg, #2a7afa 0%, transparent 100%);
  padding: 10px 0;
  box-shadow: 10px 0 25px #000;
  z-index: 4;
}
header div {
  position: relative;
}
header h1 {
  color: rgba(0,0,0,.5);
  letter-spacing: 0px;
  text-transform: capitalize;
  padding: 20px 0 20px 120px;
  background-image: url('~@/assets/images/logo.png');
  background-repeat: no-repeat;
  background-size: auto 80px;
  background-position: 20px 0;
}
header span.user_details {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgba(0,0,0,.75);
}
header button.logout {
  position: fixed;
  top: 45px;
  right: 10px;
  color: rgba(0,0,0,1) !important;
  z-index: 5;
}
@media screen and (max-width: 480px){
  header {
    height: 130px;
  }
  header h1 {
    background-size: auto 60px;
    padding: 5px 10px 20px 55px;
    font-size: 1.1em;
    background-position: 5px 0;
    text-align: right;
    height: 60px;
  }
  header span.user_details {
    font-size: .9em;
    top: 40px;
  }
  header button.logout {
    top: 80px;
  }
}
</style>
